import {Model} from "./Model"

export class Bridge {
    constructor(public model: Model) {}

    public async getSignature(signature_key: "student_signature_covid19" | string) {
        const signature = await this.model.getSignature("signature")
        if (!signature) {
            this.model.signaturePopup = {
                show: true,
                signature_key: signature_key,
                mode: "signature"
            }
        }
        return signature
    }

    public async getInitials(signature_key: "student_initials_technicalStandards_1" | string) {
        const initials = await this.model.getSignature("initials")
        if (!initials) {
            this.model.signaturePopup = {
                show: true,
                signature_key: signature_key,
                mode: "initials"
            }
        }
        return initials
    }

    public async getParentSignature(
        signature_key: "student_initials_technicalStandards_1" | string
    ) {
        const parentSignature = await this.model.getSignature("parent")
        if (!parentSignature) {
            this.model.signaturePopup = {
                show: true,
                signature_key: signature_key,
                mode: "parent"
            }
        }
        return parentSignature
    }
}
