import React, {Component} from "react"
import styles from "./CareerInformation.module.css"
import {Label} from "components/inputs/Label"
import {DataStore, SelectOption} from "data/DataStore"
import {JsonDocument, Model} from "../../Model"
import {AdvisorVO, CampusVO, ProgramVO} from "data/interfaces"
import {observable} from "mobx"
import {Select} from "components/inputs/Select"
import {findErrors} from "../../lib/functions/findErrors"
import {observer} from "mobx-react"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const rules = {
    program_name: mandatory,
    start_date: mandatory,
    campus: mandatory,
    advisor: mandatory
}

@observer
export class CareerInformation extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate CareerInformation")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private get programs(): SelectOption[] {
        return this.dataStore.getAllPrograms().map((el: ProgramVO) => {
            return {value: el.value, label: el.name}
        })
    }

    private get campuses(): SelectOption[] {
        let campuses = this.dataStore.getCampusesForProgram(this.json_data["program_name"])
        return campuses.map((el: CampusVO) => {
            return {value: el.name, label: el.name}
        })
    }

    private get advisors(): SelectOption[] {
        let advisors: AdvisorVO[] = this.dataStore.getAdvisorsArray(this.json_data.campus)
        if (!advisors) {
            return []
        }

        const firstAdvisor = advisors.shift()

        firstAdvisor.name = "Unassigned Advisor"
        // if (this.json_data.student_athlete === "basketball") {
        //     firstAdvisor.name = "Coach Chatman / Coach Skinner"
        // }

        advisors = advisors.sort(function (a, b) {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        })

        advisors.unshift(firstAdvisor)

        const data: SelectOption[] = advisors.map((advisor: AdvisorVO) => {
            return {
                label: `${advisor.name} (${advisor.email})`,
                value: advisor.email
            }
        })

        return data
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        if (id === "program_name") {
            this.json_data.campus = undefined
            this.json_data.advisor = undefined
        }
        if (id === "campus") {
            this.json_data.advisor = undefined
        }
        this.props.model.dispatcher2.dispatch()
        this.forceUpdate()
    }

    public render() {
        const model = this.props.model
        const dataStore = model.dataStore

        const p = {model, onChange: this.onChange, errors: this.errors}

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>CAREER INFORMATION</h2>
                <div className={styles.form}>
                    <Label text="Program Name" required>
                        <Select id="program_name" options={this.programs} {...p} />
                        {/* <p className={styles.inputText}>
                            If you're undecided, someone from admissions can help you decide
                        </p> */}
                    </Label>

                    <Label text="Desired Start Date" required>
                        <Select id="start_date" options={dataStore.getStartDates()} {...p} />
                    </Label>

                    <Label text="Learning Sites" required>
                        <Select id="campus" options={this.campuses} {...p} />
                    </Label>

                    <Label text="Advisor" required>
                        <Select id="advisor" options={this.advisors} {...p} />
                    </Label>
                </div>
                <p className={styles.text}>
                    <span>*</span> Indicates that a response is required
                </p>
            </div>
        )
    }
}
