import React, {Component} from "react"
import {Model} from "../../Model"
import {CareerInformation} from "../../pages/career-information"
import {Main} from "../../pages/main"
import {observer} from "mobx-react"
import {UploadDocs} from "../../pages/upload-docs"
import {SubmissionForm} from "../../pages/submission-form"

interface Props {
    model: Model
}

@observer
export class Form extends Component<Props, {}> {
    public render() {
        const model = this.props.model

        console.log("Form model:", model)

        let html = <Main model={model} /> // forms-pdf

        const form_id = model.form.form_id

        if (form_id === "application_career_information") {
            html = <CareerInformation model={model} />
        }

        if (form_id === "application_upload_docs") {
            html = <UploadDocs model={model} />
        }

        if (form_id === "submission") {
            html = <SubmissionForm model={model} />
        }

        return html
    }
}
