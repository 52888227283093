import {Model} from "./Model"

export class ModelListener {
    private YES = "yes"
    private cache = {}

    constructor(private model: Model) {
        this.onModel = this.onModel.bind(this)

        model.dispatcher2.addListener(this.onModel)
        this.onModel()
    }

    private onModel() {
        // const json_data = this.model.document.json_data
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)
        const enableTab = this.model.enableTabs.bind(this.model)
        // const disableTab = this.model.disableTabs.bind(this.model)
        // const removeTab = this.model.removeTabs.bind(this.model)
        // const addTab = this.model.addTabs.bind(this.model)

        enableTab("documents")
        enable("document_admissions")

        enableTab("upload_docs")
        enable("application_upload_docs")

        enableTab("submission")
        enable("submission")

        // todo: do not delete this, force refresh needed in the listener.
        disable("init_one_time")
    }
}
