import {action, computed, observable, runInAction, toJS} from "mobx"
import {Gate} from "./lib/gate/Gate"
import {Action, History, Location} from "history"
import {User, Visitor} from "./lib/gate/interfaces"
import {Form, FormID} from "./interfaces"
import {route} from "./lib/functions/route"
import {DataStore} from "./data/DataStore"
import {EventDispatcher} from "./lib/gate/EventDispatcher"
import Swal from "sweetalert2"

const forms: Form[] = [
    {
        form_id: "application_career_information",
        title: "APPLICATION / STUDENT INFORMATION",
        type: "web",
        enabled: true
    },
    {
        form_id: "document_admissions",
        title: "Application",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "application_upload_docs",
        title: "Upload docs",
        type: "web",
        enabled: true
    },
    {
        form_id: "submission",
        title: "submission",
        type: "web",
        enabled: true
    }
]

const tabs = [
    {
        id: "1",
        title: "Student Information",
        tab_id: "career_information",
        tabletTitle: "Student Info",
        items: [
            {
                label: "Program Selection",
                value: "application_career_information"
            }
        ],
        disabled: false,
        removed: false
    },
    {
        id: "2",
        title: "Documents",
        tab_id: "documents",
        tabletTitle: "Application Documents",
        items: [{value: "document_admissions", label: "Application"}],
        disabled: false,
        removed: false
    },
    {
        id: "3",
        title: "Upload Docs",
        tab_id: "upload_docs",
        tabletTitle: "Upload Documents",
        items: [
            // {value: "high_school_transcript", label: "High School Transcript"},
            // {value: "criminal_history_disclosure", label: "Criminal History Disclosure"},
            // {value: "ferpa", label: "FERPA"},
            // {value: "credit_evaluation", label: "Credit Evaluation"},
            {value: "application_upload_docs", label: "Upload docs"}
        ],
        disabled: false,
        removed: false
    },
    {
        id: "4",
        tab_id: "submission",
        title: "Submission",
        tabletTitle: "Submission",
        items: [
            {
                label: "Submission",
                value: "submission"
            }
        ],
        disabled: false,
        removed: false
    }
]

export class Model {
    public dispatcher = new EventDispatcher()
    public dispatcher2 = new EventDispatcher()
    private validateFunctions: Function[] = []

    @observable
    public document: UserDocument = {json_data: {}}

    @observable
    public signaturePopup: {
        show: boolean
        signature_key
        mode: "signature" | "initials" | "parent"
    } = {
        show: false,
        signature_key: "",
        mode: "signature"
    }

    @observable
    private form_id: FormID = "application_career_information"

    @observable
    public iframeLoaded = false

    @observable
    public location: Location

    @observable
    public path: string

    @observable
    public visitor: Visitor

    @observable
    public user: User | undefined

    @observable
    public appSettings: any

    @observable
    public popup = {visible: false, message: ""}

    public childBridge: any

    @observable
    public is_form_submitted = false

    @observable
    public paymentStatus: string = "pending" // pending | failed | completed

    @observable
    public paymentResponse: any = {}

    @observable
    public couponStatus: string = "not-verified" // verified | not-verified

    private parentBridge: any

    public STAGES_TABS_IDS = ["career_information", "documents", "upload_docs", "Submission"]

    public COLOR_STAGES = ["application", "others", "disabledTabs"]

    constructor(public gate: Gate, private history: History, public dataStore: DataStore) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH") // execute one time
        // @ts-ignore
        this.parentBridge = window.parent.bridge
    }

    public canSubmit(): boolean {
        const allEnabledForms = forms
            .filter((f) => {
                return f.enabled
            })
            .map((f) => {
                return f.form_id
            })
            .filter((form_id) => {
                return form_id !== "submission"
            })

        const object = toJS(this.document.json_data.forms)

        const passedForms = Object.keys(object)

        if (allEnabledForms.length === passedForms.length) {
            return true
        }

        return false
    }

    public enableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public disableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    public enableTabs(tab_id: string) {
        for (let tab of tabs) {
            if (tab.tab_id === tab_id) {
                tab.disabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    public disableTabs(tab_id: string) {
        for (let tab of tabs) {
            if (tab.tab_id === tab_id) {
                tab.disabled = true
                break
            }
        }
        this.dispatcher.dispatch()
    }

    @action
    public setFormId(id: FormID) {
        console.log("SET FORM ID=", id)
        // this.form_id = id
        this.goTo(`/form/${id}?token=${this.gate.getToken()}&user_id=${this.user?.id}`)
        this.iframeLoaded = false
    }

    @computed
    public get form(): Form {
        return forms.find((el) => {
            return el.form_id === this.form_id
        })
    }

    public goTo(url: string) {
        console.log(`GO TO URL:${url}`)
        this.history.push(url)
    }

    public getTabs(): any {
        return tabs
    }

    public getForms(): Form[] {
        return forms
    }

    public get enabledItems(): FormID[] {
        let arr = forms.filter((el) => {
            return el.enabled
        })

        return arr.map((el: Form) => {
            return el.form_id
        })
    }

    public getPreviousFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }
        if (index === 0) {
            return null
        }

        for (let i = index - 1; i >= 0; i--) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    public getNextFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i] && forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }

        if (index + 1 === forms.length) {
            return null
        }

        for (let i = index + 1; i < forms.length; i++) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    public checkByStages(style: any, disabled: boolean, tab_id: string) {
        const [career_information] = this.STAGES_TABS_IDS
        const [applicationColor, othersColor, disabledTabs] = this.COLOR_STAGES
        if (disabled) {
            return style[disabledTabs]
        } else if (tab_id === career_information) {
            return style[applicationColor]
        } else {
            return style[othersColor]
        }
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
        console.log("ON HISTORY Change=", this.path)
        if (route("/form/:id(/)", this.path)) {
            const {id} = route("/form/:id(/)*", this.path)
            this.form_id = id
        }
    }

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    @action
    public alert(message: string) {
        this.popup.visible = true
        this.popup.message = message
    }

    public get isStaff(): boolean {
        if (!this.visitor) {
            return false
        }
        const role = this.visitor.role
        if (role === "staff" || role === "admin") {
            return true
        }

        return false
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public async loadUser(user_id: number): Promise<void> {
        let r = await this.gate.request(`/users/${user_id}/get`)
        this.user = r.data
    }

    public getHistory(): History {
        return this.history
    }

    public async submit(): Promise<void> {
        const responseSubmit = await this.gate.request(`/users/${this.user.id}/submit`)
        try {
            if (responseSubmit.success) {
                await Swal.fire({
                    icon: "success",
                    title: "Application submitted",
                    text: "Thank you for submit your application. An advisor will contact you soon."
                })
            } else {
            }
        } catch (error) {}

        runInAction(() => {
            this.is_form_submitted = true
        })
    }

    public async save(): Promise<boolean> {
        let json_data

        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-pdf")
            }
            if (!(await this.childBridge.validate())) {
                await Swal.fire({
                    icon: "error",
                    title: "There are some errors.",
                    text: "Please take a look."
                })
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form

        const id = this.user.doc.id
        if (json_data.email) {
            json_data.email = json_data.email.trim()
        }
        let r = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })

        if (json_data.grade_type === "esl" || json_data.grade_type === "undergraduate") {
        }

        return r.success
    }

    public async uploadDocument(file: File, id: string) {
        const IS_MULTI = false
        const formData = new FormData()
        formData.append(id, file)
        const data = await this.gate.request(
            `/uploads`,
            {
                data: formData
            },
            IS_MULTI
        )
        return data
    }

    public signOut() {
        this.parentBridge.signOut()
    }

    public async saveAgreement(): Promise<boolean> {
        let json_data
        const id = this.user.doc.id
        if (this.form.type === "web") {
            // if (!this.validate()) {
            //     return false
            // }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }
        json_data.accepted = true
        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        if (r.success) {
            const {
                data: {
                    json_data: {accepted}
                }
            } = r

            json_data.accepted = accepted
        } else {
            if (json_data.accepted) {
                delete json_data.accepted
            }
        }

        return r.success
    }

    public async getSignature(type: "signature" | "initials" | "parent"): Promise<object | null> {
        if (!this.currentSignature) {
            let r = await this.gate.request("/signatures/get-my", {data: {type: type}})
            if (r.success) {
                this.currentSignature = r.data
            } else {
                this.currentSignature = null
            }
        }

        return this.currentSignature
    }

    public addValidateFunction(f: Function) {
        for (let item of this.validateFunctions) {
            if (item === f) {
                return
            }
        }

        this.validateFunctions.push(f)
    }

    public removeValidateFunction(f: Function) {
        console.log("removeValidateFunction")
        // @ts-ignore
        console.log(f.functionId)

        console.log(
            this.validateFunctions.map((e) => {
                // @ts-ignore
                return e.functionId
            })
        )
        for (let i = 0; i < this.validateFunctions.length; i++) {
            // @ts-ignore
            if (this.validateFunctions[i].functionId === f.functionId) {
                this.validateFunctions.splice(i, 1)
                console.log(
                    this.validateFunctions.map((e) => {
                        // @ts-ignore
                        return e.functionId
                    })
                )
                return
            }
        }
    }

    public validate(): boolean {
        let results = []
        for (let f of this.validateFunctions) {
            const r = f()
            results.push(r)
        }

        if (results.includes(false)) {
            return false
        }
        return true
    }

    public async loadVisitor() {
        let r = await this.gate.request("/users/get-me")
        this.visitor = r.data
    }

    public async loadAll(token: string, user_id: number) {
        if (!token || !user_id) {
            return
        }
        this.gate.setToken(token)
        await Promise.all([this.loadUser(user_id), this.loadVisitor()])
        this.document = this.user.doc
        console.log("MODEL>LOAD ALL")
        console.log(toJS(this.document))
        this.form_id =
            (this.document.json_data.progress as FormID) || "application_career_information"

        if (!this.document.json_data.first_name) {
            this.document.json_data.first_name = this.user.first_name
        }
        if (!this.document.json_data.last_name) {
            this.document.json_data.last_name = this.user.last_name
        }

        if (!this.document.json_data.last_name) {
            this.document.json_data.last_name = this.user.last_name
        }

        if (!this.document.json_data.home_phone) {
            this.document.json_data.home_phone = this.user.json_data.phone_number
        }

        if (!this.document.json_data.full_name) {
            this.document.json_data.full_name = `${this.user.first_name} ${this.user.last_name}`
        }

        if (!this.document.json_data.email) {
            this.document.json_data.email = `${this.user.email}`
        }

        this.dispatcher.dispatch()
        this.dispatcher2.dispatch()
    }

    public async deleteSupportedDocument(id: number) {
        const r = await this.gate.request(`/uploads/${id}/delete`)
        return r
    }

    private currentSignature
}

export interface SignatureVO {
    id
    user_id
    json_data: {
        img: string
    }
    ip // "172.17.0.1"
    created_at // "2019-10-17T11:22:43.823Z"
    updated_at // "2019-10-17T11:22:43.823Z"
    deleted_at
}

interface UserDocument {
    id?: number
    json_data: JsonDocument
}

export interface JsonDocument {
    [key: string]: string | any
}
